import React from 'react'
import './Input.css'

const Input = ({type,placeholder,value, onChange, name}) => {
  return (
    <input
    className='inputBox'
    value={value}
    name={name}
    onChange={onChange}
    type={type}
    placeholder={placeholder}
    />
  )
}

export default Input;
