import React from 'react'
import Input from '../../composant/Input'
import Button from '../../composant/Button'
import axios from 'axios';
import {useEffect, useState} from 'react'

export default function AddSobCategorie({onClose}) {

  const [categorie, setCategorie] = useState([]);
  const [sobCategorie, setSobCategorie] = useState([]);
  const [APIState, setAPIState]= useState({
    loading:false,
    error:true,
    data:undefined
  })
  const [statusEnvio, setStatusEnvio] = useState({
    codigo: '',
    type: '',
    mensagem: ''
  })

  const getCategorie= async () =>{
    setAPIState({...APIState, loading: true})
    fetch("http://localhost/RestoAfrica/src/views/categorie.php")
      .then((response) => {
        if(!response.ok) throw new Error()
        return response.json()
      })
      .then((data) => {
        setAPIState({loading:false,error:false,data:data})
      })
      .catch(()=>{
        setAPIState({loading:false,error:true,data:undefined})
      })
  }

  
  const onSubmit = (e) => {
    e.preventDefault();
    let endpoint = "http://localhost/RestoAfrica/src/views/add_sob_categorie.php";
    axios.post(endpoint, {
      categorie: categorie,
      sobCategorie:sobCategorie
    }).then((res) => {
            console.log(res.data)
      if (res.data.erro) {
        setStatusEnvio({
          codigo: 400,
          type: "error",
          mensagem: res.data.mensagem
        })
      }
      else {
        setStatusEnvio({
          codigo: 200,
          type: "success",
          mensagem: res.data.mensagem
        })
      }

    }).catch(() => {
      setStatusEnvio({
        codigo: 400,
        type: "error",
        mensagem: "tente mais tarde! "
      })
    });

  }
useEffect(()=>{
getCategorie()
},[])

  return (
    <div className="carossel">
    <div
          onClick={e => e.stopPropagation()}
          className="box-carossel"
        >
          <button
            onClick={onClose}
            className="btn-close"
          >
            X
          </button>
          <form action="" className="form-cadastro">
          <div className="input-block">
              <label htmlFor="name">Categorie</label>
              <select name="" id=""onChange={e => setCategorie(e.target.value)}>
             {APIState.data?.length > 0 && (APIState.data).map(cat => { 
              return <option value={cat.id_cat}>{cat.nome_cat}</option>
              })}
              </select>
            </div>
        <div className="input-block">
                <label htmlFor="name">Nome da Sob categoria</label>
                <Input placeholder={"Nome da Sob categoria"} onChange={e => setSobCategorie(e.target.value)}/>
            </div>
            <Button Text={"Add Sob Categoria"} onClick={onSubmit}/>
            </form>
            </div>
    </div>
  )
}
