import React, { useState } from 'react'
import './SwitchMode.css'

const SwitchMode = ({type,placeholder,value, onClick, }) => {
  const [ativo, setAtivo]=useState();
  const Animatedtoggle =  (e) => {
    const btn = e.currentTarget.parentElement
    
     btn.classList.toggle('active')
     const text = btn.children[1]
     if(btn.classList.contains("active")){
       //text.innerHTML="ATIVADO"
       setAtivo('ATIVADO')
     }
     else{
       //text.innerHTML="DESATIVADO"
       setAtivo('DESATIVADO')
     }
   
   }
  return (
    <div className='switch'>
                          <div className='toggle'>
                            <div className='toggle-button' onClick={Animatedtoggle} data-status = ""></div>
                            <div className='text'>{ativo}</div>
                          </div>
                        </div>
  )
}

export default SwitchMode;
