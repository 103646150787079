import React from 'react'
import { useEffect, useRef } from 'react';
import { useState } from 'react';

import{useSelector, useDispatch} from "react-redux"
import { getPostsLists } from '../../redux/blogReducer';

import './Blog.css'
import { useNavigate } from 'react-router-dom'

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'draftjs-to-html'
import draftToMarkdown from 'draftjs-to-markdown';
import { convert } from 'html-to-text'
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import NavMenu from '../../composant/navMenu/NavMenu';
import Slider from '../../composant/Slider/Slider';
import Footer from './../../composant/Footer/Footer';
import { Link } from 'react-router-dom';



export default function Restaurantes() {

    const dispatch = useDispatch()
    const posts = useSelector(state=>state.blogReducer)

    const postsUnicos = [];
     
    let content
      if(!posts.items && !posts.loading && !posts.error){
          dispatch(getPostsLists())
      }
      else if(posts.loading){
          //content = <img src={spinner} alt='spinning loader'/>
      }
      else if(posts.error){
          content = <p> An error has occured</p>
      }
      else if(posts.items){
          Object.values(posts.items).forEach((item) => {
              var duplicated = postsUnicos.findIndex(redItem => {
                  return item.titre_post === redItem.titre_post;
              }) > -1
              if (!duplicated) {
                postsUnicos.push(item);
              }
      
          });
      }

    const [readMore, setReadmore] = useState();
    const LIMIT = 300;
    const [dataRestaurantes, setDataRestaurantes] = useState([]);
    const [titre, setTitre] = useState();
    const [categorie, setCategorie] = useState()
    const carousel = useRef(null);
    const UrlBase = 'http://localhost/RestoAfrica/src/views/';
    const getRestaurantes = async () => {
        fetch(UrlBase + 'blog.php')
            .then(response => response.json())
            .then(data => {

                setDataRestaurantes(data);
                setTitre(data.titre)
                setCategorie(data.categorie)
            })

    }


    useEffect(() => {
        getRestaurantes();

    }, []);

    const handleLeftClick = (e) => {
        e.preventDefault();
        console.log(carousel.current.offsetWidth);
        carousel.current.scrollLeft -= carousel.current.offsetWidth;
    }
    const handleRightClick = (e) => {
        e.preventDefault();
        carousel.current.scrollLeft += carousel.current.offsetWidth;
    }
    console.log(dataRestaurantes);
    const navigate = useNavigate()
    const goHome = () => { navigate("/") }
    const recetteUnicos = [];
    const categorieUnico = new Map();

    Object.values(dataRestaurantes).forEach((item) => {
        var duplicated = recetteUnicos.findIndex(redItem => {
            return item.titre_post == redItem.titre_post;
        }) > -1
        if (!duplicated) {
            recetteUnicos.push(item);
        }

    });
    dataRestaurantes.forEach((cat) => {
        if (!categorieUnico.has(cat.categorie)) {
            categorieUnico.set(cat.categorie, cat)
        }

    });
    const cat = [...categorieUnico.keys()]
    console.log(cat);

    function createMarkup(html) {
        console.log(html.substr(0, LIMIT))
        return {
          __html: DOMPurify.sanitize(html.substr(0, LIMIT))
         
        }}
    return (
        <>
         <NavMenu />

<Slider />
<h1>Blog</h1>
            <section className='container-blog'>

                {(Object.values(postsUnicos).map(resto =>
                    <div className='carte-blog'>
                        <Link to={
                            `/single_blog/${resto.titre_post.trim().replace(/\s+/g, '-')}`
                            }
                            
                            state={{
                                titre: resto.titre_post,
                                resumo: resto.resumo_post,
                                texto: resto.texto_post,
                                imagem: resto.imagem_post,
                                id_post: resto.id_post,
                                date_created:resto.dateCreated_post
    
                            }}
                            >
                            <img id="logo" src={"https://congolinaria.com.br/" + resto.imagem_post} alt="Congolinaria" />

                            <h2 key={resto.id_post}>{resto.titre_post} </h2>

                        </Link>
                        <div
                  className='titre-post' key={resto.id_post}
                  dangerouslySetInnerHTML={createMarkup(resto.resumo_post)}>

                </div>
                        
                    </div>


                ))}


            </section>
            <Footer />
        </>


    )
}
