import React from 'react'
import Input from './../../composant/Input'
import Button from '../../composant/Button'
import api from '../../config/configApi';
import axios from 'axios';
import {useEffect, useState} from 'react'

export default function AddCategorie({onClose}) {

  const [categorie, setCategorie] = useState([]);
  const [statusEnvio, setStatusEnvio] = useState({
    codigo: '',
    type: '',
    mensagem: ''
  })

  const onSubmit = (e) => {
    e.preventDefault();
    let endpoint = "http://localhost/RestoAfrica/src/views/add_categorie.php";
    axios.post(endpoint, {
      categorie: categorie
    }).then((res) => {
            console.log(res.data)
      if (res.data.erro) {
        setStatusEnvio({
          codigo: 400,
          type: "error",
          mensagem: res.data.mensagem
        })
      }
      else {
        setStatusEnvio({
          codigo: 200,
          type: "success",
          mensagem: res.data.mensagem
        })
      }

    }).catch(() => {
      setStatusEnvio({
        codigo: 400,
        type: "error",
        mensagem: "tente mais tarde! "
      })
    });

  }
    return (
        <div className="carossel">
  <div
        onClick={e => e.stopPropagation()}
        className="box-carossel"
      >
        <button
          onClick={onClose}
          className="btn-close"
        >
          X
        </button>
        <form action="" className="form-cadastro">
            <div className="">
                <label htmlFor="name">Nome da categoria</label>
                <Input placeholder={"Nome da categoria"} onChange={e => setCategorie(e.target.value)}/>
            </div>
            <Button Text={"Salvar Categoria"} onClick={onSubmit}/>
            </form>
</div>
        </div>
    )
}
