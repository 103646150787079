import { useEffect } from 'react';
import { useState } from 'react';
import {Table,Titulo,ButtonPrimary,AlertSucess,AlertDanger} from './styles'

import { Link, useParams } from 'react-router-dom';
import NavMenuAdmin from '../../composant/navMenu/NavMenuAdmin';
import Sidebar from '../../composant/Sidebar/Sidebar';

import{useSelector, useDispatch} from "react-redux"
import { getPostsLists } from '../../redux/blogReducer';
//import './App.css';

//import Forms from '../Forms/Forms';
//import Sidebar from '../Sidebar/Sidebar';

export default function  Home () {

  const dispatch = useDispatch()
    const posts = useSelector(state=>state.blogReducer)
  console.log(useParams());

  const postsUnicos = [];
     
    let content
      if(!posts.items && !posts.loading && !posts.error){
          dispatch(getPostsLists())
      }
      else if(posts.loading){
          //content = <img src={spinner} alt='spinning loader'/>
      }
      else if(posts.error){
          content = <p> An error has occured</p>
      }
      else if(posts.items){
          Object.values(posts.items).forEach((item) => {
              var duplicated = postsUnicos.findIndex(redItem => {
                  return item.titre_post === redItem.titre_post;
              }) > -1
              if (!duplicated) {
                postsUnicos.push(item);
              }
      
          });
      }

  const [dataCardapio, setDataCardapio] = useState([]);
  const [statusEnvio, setEnvioStatus]= useState({
    type: '',
    mensagem:''
  })
  
  const url = "https://congolinaria.com.br/"
  const getMenu = async () => {
    fetch(url + 'blog.php')     
    .then(response => response.json())
    .then(data => {
      console.log(data);
      setDataCardapio(data);
    })

  }
  /*
  const getRecette = async () => {
    fetch('/pilafs_et_risottos.json',{ 
    headers: {
      Accept: "application/json"}
    })     
    .then(response => response.json())
    .then(data => {
      console.log(data);
      setDataCardapio(data);
      
    })
  }*/
  const deletarPosts = async (id_post) =>{

    await fetch(url+"deletar_post.php?id="+ id_post)
            .then((response)=> response.json())
            .then((responseJson)=>{
              if(responseJson.erro) {
                setEnvioStatus({
                  type: 'erro',
                  mensagem:responseJson.mensagem
                });
                
              }else{
                setEnvioStatus({
                  type: 'sucess',
                  mensagem:responseJson.mensagem
                });
                getMenu(); 
              }
                console.log(responseJson);                
            }).catch(()=>{
              setEnvioStatus({
                type: 'erro',
                mensagem:"Erro: Produto não apagado com sucesso tente mais tarde "

              });
              
            });
  };

  useEffect(() => {
    //getRecette();
    getMenu(); 
     }, [])

  return (
<>
<Sidebar/>
<NavMenuAdmin/>
      <section id='content'>
<main>
<div class="head-title">
				<div class="left">
					<h1>Dashboard</h1>
					<ul class="breadcrumb">
						<li>
							<a href="/admin/">Dashboard</a>
						</li>
						<li><i class='bx bx-chevron-right' ></i></li>
						<li>
							<a class="active" href="/admin/">Home</a>
						</li>
            
            
					</ul>
          
				</div>
       
				
			</div>
      <a  href="/post/" class="btn-download" >
					<i class='bx bxs-cloud-download' ></i>
					<span class="text">Adicionar Post</span>
				</a>
     
			<ul class="box-info">
				<li>
					<i class='bx bxs-calendar-check' ></i>
					<span class="text">
						<h3>50</h3>
						<p>Recettes</p>
					</span>
				</li>
				<li>
					<i class='bx bxs-group' ></i>
					<span class="text">
						<h3>2834</h3>
						<p>Posts</p>
					</span>
				</li>
				<li>
					<i class='bx bxs-dollar-circle' ></i>
					<span class="text">
						<h3>$2543</h3>
						<p>Total Sales</p>
					</span>
				</li>
			</ul>


			<div class="table-data">
				<div class="order">
					<div class="head">
						<h3>Posts</h3>
						<i class='bx bx-search' ></i>
						<i class='bx bx-filter' ></i>
      {statusEnvio.type==='erro' ?<AlertDanger>{statusEnvio.mensagem}</AlertDanger> :""}
      {statusEnvio.type=== 'sucess' ? <AlertSucess>{statusEnvio.mensagem}</AlertSucess> :""}
					</div>
					<table>
					<thead>
          <tr>
            <th>Titúlo</th>
            
            <th>Ação</th>
            
          </tr>
        </thead>
		<tbody>
          {
          Object.values(postsUnicos).map((receita, index) => {
          return(
              <tr key={index}>
                <td>
              <img  id="logo" src={url+receita.imagem_post} alt="Congolinaria" />
                <p>{receita.titre_post}</p>
              </td>
              <td> 
                <Link to={"/visualizar/"+ receita.id_post}>
                  <ButtonPrimary><i class="fa-solid fa-eye"></i>Visualizar</ButtonPrimary></Link>
                <Link to={"/editarPosts/"+ receita.id_post}
                state={{
                  titre: receita.titre_post,
                  categorie:receita.categorie_post,
                  resumo: receita.resumo_post,
                  texto: receita.texto_post,
                  imagem: receita.imagem_post,
                  id_post: receita.id_post,
                  date_created:receita.dateCreated_post

              }}
                >
                  <ButtonPrimary><i class="fa-solid fa-pen"></i> Editar</ButtonPrimary></Link> 
                <ButtonPrimary onClick={()=>deletarPosts(receita.id_post)}><i class="fa-solid fa-trash"></i>Apagar</ButtonPrimary>
                </td>
              </tr>
          );
          })}
        </tbody>
					</table>
				</div>
			</div>
</main>
    
    </section>
</>

  );
}


