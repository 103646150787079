import {createSlice} from "@reduxjs/toolkit"
import api from '../config/configApi'
const initialState ={
    items:undefined,
    loading: false,
    error: false,
    dataItems:[

    ],
}
export const blogReducer = createSlice({
    name:"sliderReducer",
    initialState,
    reducers:{
        addSlider: (state,action)=>{
            state.items = action.payload
        
        },
        
        addPost: (state, action) => {
            //state.data = action.payload
            state.data.push(action.payload);
          },/*
          editData: (state, action) => {
            state.data.find(el => el.id_post === action.payload.id_post)
          },*/
          deleteData: (state, action) => {
            const indexOfItemToRemove = state.data.findIndex(el => el.id_produto === action.payload)
            state.data.splice(indexOfItemToRemove, 1)
      
          },
          addError: (state, action) => {
            state.error = true
            state.loading = false
          },
    },
    extraReducers: {
        ["cart/createCartItem"]: (state, action) => {
         
         
          state.items.find(el => el.id_produto === action.payload.id_produto).picked = "1"
        },
        ["cart/deleteFromCart"]: (state, action) => {
          state.items.find(el => el.id_produto === action.payload).picked ="0"
        }
      }

})


export function getSlidersLists(action){
    return function (dispatch, getState) {
      api.get('/slider/slider.php')
        .then(response => {
          console.log(response.data)
          dispatch(addSlider(response.data))
        })
    }
}
export function addUser(data) {
    return function (dispatch, getState) {
      return api.post('/usuarios.php', data)
        .then(response => {
          dispatch(addPost(data));
          console.log(response.data)
        }).catch(() =>
          dispatch(addError()))
    }
  }

  export function editPost(data) {
console.log(data)
    return function (dispatch, getState) {
      return api.put('edit_slider.php', data)
        .then(response => {
         // dispatch(editData(data));
         console.log(response.data)
  
        }).catch(() =>
          dispatch(addError())
        )
          
    }
  }
  
  export function deleteUser(id_comments) {
    return function (dispatch) {
      return api.post("/usuarios.php?id=" + id_comments)
        .then(response => {
          dispatch(deleteData(id_comments))
  
        }).catch(() =>
          dispatch(addError()))
    }
    
  };
export const{addSlider,addPost,addError,deleteData,editData} = blogReducer.actions
export default blogReducer.reducer