import React from 'react'

import { useLocation } from 'react-router-dom'
import{useSelector, useDispatch} from "react-redux"
import { getSlidersLists } from '../../redux/sliderReducer';

import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import * as C from './styles';
import Button from "../../composant/Button"
import Input from "../../composant/Input"
import Textarea from "../../composant/Textarea"
import ButtonSelect from "../../composant/ButtonSelect";
import FormEdit from '../../composant/FormsSliders/FormEdit'

import FormsEdit from './FormsEdit';
import api from '../../config/configApi'


export default function EditarSlider(props) {

    const dispatch = useDispatch()
    const location = useLocation()

    const [minhaEscolha, setminhaEscolha] = useState("");
    //console.log(minhaEscolha);
    const fooSim = () => {
        setminhaEscolha("vegan");
    }
    const fooNao = () => {
        setminhaEscolha("Não vegan")
    }

    const [dataCardapio, setDataCardapio] = useState([]);
    const params = useParams();
    const idcad = useState(params.id);
    const [titre, setTitre] = useState('');
    const [resumo, setResumo] = useState('');
    const [texto, setTexto] = useState('');
    const [image, setImage] = useState('');
    const id_recettes = idcad[0];
    const [categorie, setCategorie]=useState('');
    

    const [recettes, setRecettes] = useState([
        { titre: '' },
        { resumo: '' },
        { texto: '' },
        { image: '' },
        { categorie: '' }

    ]);

   
    const editTodo = async e => {
        e.preventDefault();
        console.log(titre);
       

        const newrecettes = [...recettes]
        const newCardapio = {};
        newCardapio.id_recettes = idcad[0];
        newCardapio.titre = titre;
        newCardapio.resumo = resumo;
        newCardapio.texto = texto;
        newCardapio.image = image;
        newCardapio.categorie = categorie;
        newrecettes.push(newCardapio);
        setRecettes(newrecettes);
        //console.log(newCardapio);
       

       /* const formData = new FormData();
        formData.append('image',image);
        formData.append('id_recettes' , idcad[0]);
        formData.append('titre' , titre);
        formData.append('infos' , infos);
        formData.append('ingredients' , ingredients);
        formData.append('etapes' , etapes);
        formData.append('vegan' , isChecked);
        formData.append('sinopse' , sinopse);
        formData.append('categorie' , categorie);*/
        const form ={
            id_recettes:idcad[0],
            image: image,
            titre: titre,
            resumo:resumo,
            texto : texto,
            categorie:categorie
        }
      
        const headers = {
            'headers': {
              'Content-Type': 'application/json'
            }
          }
        await api.post("/editar_recette_cuisine_libre.php",form)
        .then((response)=>{
            console.log(response.data)
            
        }).catch((err)=>{
            
        })
       /* 
        await fetch('https://refugiadonacozinha.com.br/editar_recette.php', {
            method: 'POST',
            Headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id_recettes, titre, infos, ingredients, etapes, image, url, categorie })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);

            });*/

    }


    useEffect(() => {
        const getProduto = async () => {
            await fetch("http://localhost/RestoAfrica/src/views/single_blog.php?id=" + idcad)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log(responseJson);
                    setDataCardapio(responseJson.produto);
                    //setTitre(responseJson.produto.titre_post);
                    setResumo(responseJson.produto.resumo_post);
                    setTexto(responseJson.produto.texto_post);
                    setImage(responseJson.produto.imagem_post);
                    setCategorie(responseJson.produto.categorie_post);
                    
                })
        }
        getProduto();
    }, []);

    const navigate = useNavigate()
    const goHome = () => { navigate("/") }
    

    return (
        <>

        <FormEdit idpost ={idcad[0]} dataPosts = {dataCardapio} 
        titre={location.state.titre}
        texto={location.state.texto}
        id_slider={location.state.id_slider}/>

        </>
    )
}
