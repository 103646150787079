import { useSelector, useDispatch } from "react-redux"
import { useState,useEffect } from "react"
import Input from './../../composant/Input'
import Textarea from './../../composant/Textarea'
import AddCategorie from './AddCategorie';
import AddSobCategorie from './AddSobCategorie';


import { useNavigate } from 'react-router-dom'

import "./AddBebidas.css"
import axios from "axios"
import Button from "../../composant/Button"
import SwitchMode from "../../composant/SwitchMode/SwitchMode"
export default function AddBebidas({onClose}) {
console.log(document.querySelector(".toggle").children[1])

const [categorie, setCategorie] = useState([]);
const [sobCategorie, setSobCategorie] = useState([]);
const [showCategorie, setShowCategorie] = useState(false)
  const [ showSobCategorie, setShowSobCategorie]= useState(false)
const [APIState, setAPIState]= useState({
  loading:false,
  error:true,
  data:undefined
})
const [APIStateCat, setAPIStateCat]= useState({
  loading:false,
  error:true,
  data:undefined
})
const [statusEnvio, setStatusEnvio] = useState({
  codigo: '',
  type: '',
  mensagem: ''
})

const [idcat, setIdcat]= useState('');
const handlecategorie=(event)=>{
const getcoutryid= event.target.value;
setIdcat(getcoutryid);
event.preventDefault();
}
const getCategorie= async () =>{
  setAPIState({...APIState, loading: true})
  await fetch("http://localhost/RestoAfrica/src/views/categorie.php")
    .then((response) => {
      if(!response.ok) throw new Error()
      return response.json()
    })
    .then((data) => {
      setAPIState({loading:false,error:false,data:data})
    })
    .catch(()=>{
      setAPIState({loading:false,error:true,data:undefined})
    })
}

const getSobCategorie= async () =>{
  setAPIStateCat({...APIStateCat, loading: true})
   await fetch("http://localhost/RestoAfrica/src/views/sob_categorie.php?id=" + categorie)
    .then((response) => {
      if(!response.ok) throw new Error()
      return response.json()
    })
    .then((data) => {
      setAPIStateCat({loading:false,error:false,data:data})
    })
    .catch(()=>{
      setAPIStateCat({loading:false,error:true,data:undefined})
    })
}

useEffect(()=>{
getCategorie()
},[])
useEffect(()=>{
getSobCategorie()
},[categorie])

const dias = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sabádo"];
const [checked, setChecked] = useState([]);
// Add/Remove checked item from list
const handleCheck = (event) => {
  var updatedList = [...checked];
  
  if (event.target.checked) {
    updatedList = [...checked, event.target.value];
  } else {
    updatedList.splice(checked.indexOf(event.target.value), 1);
  }
  setChecked(updatedList);
};

// Generate string of checked items
const checkedItems = checked.length
? checked.reduce((total, item) => {
    return total + ", " + item;
  })
: "";

// Return classes based on whether item is checked
var isChecked = (item) =>
checked.includes(item) ? "checked-item" : "not-checked-item";

  return (
    <div onClick={onClose} className="carossel" >
      <div
        onClick={e => e.stopPropagation()}
        className="box-carossel"
      >
        <button
          onClick={onClose}
          className="btn-close"
        >
          X
        </button>

        <form action="" className="form-cadastro">
        <div >


            <div className="input-block">
              <label htmlFor="name">Categorie  </label>
              <select name="" id="" onChange={e => setCategorie(e.target.value)}>
              <option>--Select Categorie--</option>
              {APIState.data?.length > 0 && (APIState.data).map(cat => { 
              return <option key={cat.id_cat} value={cat.id_cat}>{cat.nome_cat}</option>
              })}
              </select>
              <span onClick={() => setShowCategorie(!showCategorie)}>+ categorie</span>
            </div>

            <div className="input-block">
              <label htmlFor="name">Sob-Categorie </label>
              <select name="" id="">
              <option>--Select Sob Categorie--</option>
             
              {APIStateCat.data?.length > 0 && (APIStateCat.data).map((ct,index) => { 
               return <option key={index} value={ct.id_sob_cat}>{ct.name_sob_cat}</option>
              })}
              </select>
              <span onClick={() => setShowSobCategorie(!showSobCategorie)}>+ Sob categorie</span>
              {showCategorie && <AddCategorie  onClose={() => setShowCategorie(false)} />}
            {showSobCategorie && <AddSobCategorie  onClose={() => setShowSobCategorie(false)} />}
            </div>
                        <div className="input-block">
                            <label htmlFor="name">Nome do item</label>
                            <Input placeholder={"Nome do item"}/>
                           
                        </div>

                        <div className="input-block">
                            <label htmlFor="name">Descrição</label> 
                         <Textarea placeholder={"Descrição"}/>
                        </div>
                        <div className="input-block">
                            <label htmlFor="name">Valor</label>
                            <Input placeholder={"Valor"}/>
                        </div>

                        <div className="input-block" >
                           <label htmlFor="">
                           <h3>Disponibilidade</h3>
                            <p >Dias  da semana</p></label> 

                            <div className="input-block dias-container" >
                            {dias.map((item, index) => (
                              <label class="container" key={index}>{item}
                                <input value={item} type="checkbox" onChange={handleCheck}/>
                                <span class="checkmark"></span>
                              </label>
                            ))}
                            
                              
                            </div>

                                            <div>
                        {`Dias disponiveis: ${checkedItems}`}
                      </div>
                        </div>
        </div>
        <Button Text={"Cadastrar Item"}/>
        </form>
      </div>
    </div>
  )
}
