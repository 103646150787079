import'./Cardapio.css'
import React,{useEffect, useState} from 'react'
import Footer from './../../composant/Footer/Footer'
import NavMenu from '../../composant/navMenu/NavMenu';
import NavMenuAdmin from '../../composant/navMenu/NavMenuAdmin';
import Sidebar from '../../composant/Sidebar/Sidebar';
import Slider from '../../composant/Slider/Slider';
import NavCardapio from '../../composant/navCardapio/NavCardapio';
import FormSearch from '../../composant/FormSearch';
import  {dataCardapio}  from './dataCardapio';
import spinner from './../../assets/images/spinner.gif'
import AddBebidas from './AddBebidas';
import AddCategorie from './AddCategorie';
import AddSobCategorie from './AddSobCategorie';
import SwitchMode from '../../composant/SwitchMode/SwitchMode';
import api from '../../config/configApi';

export default function CardapioAdmin() {
  // dias da semana  automatico
  const [showModalCart, setShowModalCart] = useState(false)
  const [showCategorie, setShowCategorie] = useState(false)
  const [ showSobCategorie, setShowSobCategorie]= useState(false)
  const [categorieCard, setCategorieCard]=useState("")
  const [txt, setTxt]=useState("Ativo");
  const [APIState, setAPIState]= useState({
    loading:false,
    error:true,
    data:undefined
  })
  const [statusEnvio, setEnvioStatus]= useState({
    type: '',
    mensagem:''
  })
  let agora = new Date();
  let diaSem = agora.getDay()
const getdia=()=>{
  switch(diaSem){
    case 0:
    return"Domingo"
    case 1:
    return"Segunda-Feira"
    case 2:
    return"Terça-Feira"
    case 3:
    return"Quarta-Feira"
    case 4:
    return"Quinta-Feira"
    case 5:
    return"Sexta-Feira"
    case 6:
    return"Sábado"
    
  }
}
const getBebida= async () =>{
  setAPIState({...APIState, loading: true})
  fetch("http://localhost/RestoAfrica/src/views/bebidas.php")
    .then((response) => {
      if(!response.ok) throw new Error()
      return response.json()
    })
    .then((data) => {
      setAPIState({loading:false,error:false,data:data})
    })
    .catch(()=>{
      setAPIState({loading:false,error:true,data:undefined})
    })
}
const deletarRecettes = async (id) =>{
  await api.delete("/deletar_bebida.php?id="+ id)
  .then((response)=>{
      console.log(response.data)
      
      if(response.data.erro) {
        setEnvioStatus({
          type: 'erro',
          mensagem:response.data.mensagem
        });
        
      }else{
        setEnvioStatus({
          type: 'sucess',
          mensagem:response.data.mensagem
        });
        getBebida(); 
      }
        console.log(response.data);                
    }).catch(()=>{
      setEnvioStatus({
        type: 'erro',
        mensagem:"Erro: Produto não apagado com sucesso tente mais tarde "

      });
      
    });
}

useEffect(()=>{
let boxs = document.querySelectorAll("section.rodizio .box-container .box")
let buttons = document.querySelectorAll(".toggle-button")
console.log(buttons)
boxs.forEach(box=>{
  box.classList.remove("active")
  if(box.dataset.dia===getdia()){
    box.classList.add("active")
  }
})

buttons.forEach(button=>{
  if(button.dataset.status==="ATIVADO"){
    button.parentElement.classList.add("active")
  }
  
})
getBebida()
},[])

const [isChecked, setIsChecked] = useState();

const Animatedtoggle =  (e) => {
 const btn = e.currentTarget.parentElement
 
  btn.classList.toggle('active')
  const text = btn.children[1]
  if(btn.classList.contains("active")){
    text.innerHTML="ATIVADO"
  }
  else{
    text.innerHTML="DESATIVADO"
  }

}

let content;
let categorie;
let cardapio;
 if(APIState.loading) content = <img src={spinner} width={100} alt="icone de chargement"/>
 else if(APIState.error) content = <p> Une erreur est survenue</p>
 else if(APIState.data?.length > 0){
  
  content = 
    
    (APIState.data).map(resto => {     
      return (
      <>
						<tbody >
              <tr>
                <td><h3>{resto.titre}</h3></td>
                <td><span>{resto.price}</span></td>
                <td>
                <div className='switch'>
                          <div className='toggle'>
                            <div className='toggle-button' onClick={Animatedtoggle} data-status = ""></div>
                            <div className='text'></div>
                          </div>
                        </div>
                </td>
                <td>ver/editar/excluir</td>
              </tr>
            </tbody>                 
      </>
      
      
      )
      
    })

 }

 if(APIState.loading) categorie = <img src={spinner} width={100} alt="icone de chargement"/>
 else if(APIState.error) categorie = <p> Une erreur est survenue</p>
 else if(APIState.data?.length > 0){
  const categorieUnico = new Map();
  const categorieUnique = new Map();
  APIState.data.forEach((cat) => {
    if (!categorieUnico.has(cat.sous_categorie)) {
        categorieUnico.set(cat.sous_categorie, cat)
    }
    if(!categorieUnique.has(cat.categorie)){
      categorieUnique.set(cat.categorie,cat)
    }

});
const cat = [...categorieUnico.keys()]
const catUnique=[...categorieUnique.keys()]
  categorie =
  (cat).map(resto => {
    const bebidas =  APIState.data.filter(bebida => bebida.sous_categorie?.includes(resto))
     
    return (
    <>
    <div class="table-data">
				<div class="order">
          
					<div className="head-admin">
						<h3>{resto}  </h3>
            <div className='btn-item'>
             <span > <i className='fa-solid fa-plus'></i> Adicionar Item</span>
						<i class='bx bx-dots-vertical-rounded' ></i>

                       
                          
            </div>
           
					</div>
          <table  >
						<thead>
							<tr>
								<th>Item</th>
								<th>Preço</th>
								<th>Status de venda</th>
								<th>Ação</th>
							</tr>
						</thead>
          {

(bebidas).map(resto => {
     
  return (
  <>
        <tbody >
          <tr>
            <td><h3>{resto.titre}</h3></td>
            <td><span>{resto.price}</span></td>
            <td>
            <div className='switch'>
                      <div className='toggle'>
                        <div className='toggle-button' onClick={Animatedtoggle} data-status = {resto.status}></div>
                        <div className='text'>{resto.status}</div>
                      </div>
                     
                    </div>
            </td>
            <td>ver/editar/excluir</td>
          </tr>
        </tbody>                 
  </>
  
  
  )
  
})


          }
          </table>
 </div></div>
    
    </>)
    
  });
cardapio = 
catUnique.map(resto => {
  const menus =  APIState.data.filter(menu => menu.categorie?.includes(resto))
  const cardapios =  APIState.data.filter(cardapio => cardapio.sous_categorie?.includes(resto))
  
  
  
   
  return (

    <>
    <section   className="cardapio" id="bebidas ">
      

      <h3 className="sub-heading">{resto}</h3>
     
      <h1 className="heading">{resto}</h1>
      
       
        <div class="table-data">
				<div class="order">

            <div className="head-admin">
						<h3>{resto}  </h3>
            <div className='btn-item'>
             <span onClick={() => {setShowModalCart(!showModalCart)
             setCategorieCard(resto)}}> + Adicionar Item</span>
						<i class='bx bx-dots-vertical-rounded'  ></i>
            </div>
            {showModalCart && <AddBebidas categorie = {categorieCard} onClose={() => setShowModalCart(false)} />} 
            {showCategorie && <AddCategorie categorie = {categorieCard} onClose={() => setShowCategorie(false)} />}
            {showSobCategorie && <AddSobCategorie categorie = {categorieCard} onClose={() => setShowSobCategorie(false)} />}
					</div>
          <table  >
						<thead>
							<tr>
								<th>Item</th>
								<th>Preço</th>
								<th>Status de venda</th>
								<th>Ação</th>
							</tr>
						</thead>
       {
       menus.map(menu=>{
           
           const bebidas =  APIState.data.filter(bebida => bebida.sous_categorie?.includes(menu))
        return(
          <>
        <tbody >
          <tr>
            <td><h3>{menu.titre}</h3></td>
            <td><span>{menu.price}</span></td>
            <td>
            {/*<div className='switch'>
                      <div className='toggle'>
                        <div className='toggle-button' onClick={Animatedtoggle} data-status = {menu.status}></div>
                        <div className='text'>{menu.status}</div>
                      </div>
                    </div>*/}
                    <SwitchMode />
            </td>
          <td>{(<div className='menu-acao'>
           <span>ver item</span> 
           <span>editar item</span>
           <span onClick={()=>deletarRecettes(menu.id_bebida)}>excluir item</span>
           </div>)}
          </td>
          </tr>
        </tbody>                 

          </>
        )
       })}       
             </table> </div></div>
      {/*categorie*/}
           
      

    </section>
    </>
  )
})
}

  return (
    <>
    <Sidebar/>
<NavMenuAdmin/>

     <div id='content'>
     <div className='btn-item'>
  <span onClick={() => setShowCategorie(!showCategorie)}> + Adicionar categoria</span>
</div>
<div className='btn-item'>
  <span onClick={() => setShowSobCategorie(!showSobCategorie)}> + Adicionar sob categoria</span>
</div>
      {cardapio}

      </div>
      
    </>
  )
}
