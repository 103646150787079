import React from 'react'

import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { getPostsLists } from '../../redux/blogReducer';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'draftjs-to-html'
import draftToMarkdown from 'draftjs-to-markdown';
import { convert } from 'html-to-text'
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import { useEffect, useState } from "react";
import { useParams, Navigate } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Aside from '../Restaurantes/Aside';
import { Container, ConteudoProd, Sinopse, Categorie, ColumnRecettes, Colonne, ButtonPrimary, 
  SugesRecette, ContainerRecetteSingle, ContainerRecette, ImagemRecette, TituloRecette, LinkRecette, SubRecette } from './styles';
import NavMenu from '../../composant/navMenu/NavMenu';
import Slider from '../../composant/Slider/Slider';
import FormsComment from '../../composant/FormComment/FormsComment';
import Login from '../Login/Login';
import RegisterUser from '../RegisterUser';
import Footer from './../../composant/Footer/Footer';
import Moment from 'moment';
import api from './../../config/configApi'

import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,

} from "react-share";

import {
  FacebookShareCount,
  HatenaShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,

  WhatsappIcon,

} from "react-share";



export default function SingleBlog(props) {

  const dispatch = useDispatch()
  const location = useLocation()


  const LIMIT = 300;
  
  const params = useParams();
  const idcad = useState(params.id);
 

  const navigate = useNavigate()
 
  useEffect(() => {

  }, []);



  const posts = useSelector(state => state.blogReducer)


    const postsUnicos = [];
    let elemento=[];
    let content

    if (!posts.items && !posts.loading && !posts.error) {
        dispatch(getPostsLists())
    }
    else if (posts.loading) {
        //content = <img src={spinner} alt='spinning loader'/>
    }
    else if (posts.error) {
        content = <p> An error has occured</p>
    }
    else if (posts.items) {
        Object.values(posts.items).forEach((item) => {
            var duplicated = postsUnicos.findIndex(redItem => {
                return item.titre_post === redItem.titre_post;
            }) > -1
            if (!duplicated) {
                postsUnicos.push(item);
            }
         

        });
        if(postsUnicos){
          function pegarTitre(element){
            return idcad[0]===element.titre_post.replace(/\s+/g, '-')
          }
          elemento = postsUnicos.find(pegarTitre)
          
        }
    }
 
  
  function goHome() {
    //return <Navigate to="/" replace />
    navigate("/", { replace: true })
  }

  function createMarkup(html) {
    console.log(html.substr(0, LIMIT))
    return {
      __html: DOMPurify.sanitize(html)

    }
  }

  return (
    <>
      <NavMenu />

      <Slider />

      {props.modalLogin && <Login func={props.func} funcRegister={props.funcRegister} />}
      {props.modalRegister && <RegisterUser func={props.func} funcRegister={props.funcRegister} />}


      <ContainerRecette>
        <ColumnRecettes>
          
          <ContainerRecetteSingle>

          {elemento.titre_post &&<TituloRecette >{elemento.titre_post} </TituloRecette>}
<div 
style={{
  background: '#fff',
  margin:'10px',
  marginBottom:'20px',
  display:'flex',
  gap:'2rem',
 
}}
>
  { elemento.titre_post &&<FacebookShareButton url={`https://congolinaria.com.br/single_blog/${elemento.titre_post.replace(/\s+/g, '-')}`}
              quote={"titre"}
              hashtag={'#congolinaria...'}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>}

            {elemento.titre_post &&<WhatsappShareButton url={`https://congolinaria.com.br/single_blog/${elemento.titre_post.replace(/\s+/g, '-')}`}
              quote={"titre"}
              hashtag={'#congolinaria...'}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>}

            {elemento.titre_post && <EmailShareButton url={`https://congolinaria.com.br/single_blog/${elemento.titre_post.replace(/\s+/g, '-')}`}
              quote={"titre"}
              hashtag={'#congolinaria...'}>
              <EmailIcon size={32} round={true} />
            </EmailShareButton>}
  </div>
            {elemento.imagem_post && <ImagemRecette id="logo" src={"https://congolinaria.com.br/" + elemento.imagem_post} alt="Congolinaria"></ImagemRecette>}

            <p> Publicado em : {Moment(elemento.dateCreated_post).format('DD/MM/YYYY HH:mm')}
            </p>


            {elemento.texto_post && (<div className='titre-post' key={elemento.id_post}
              dangerouslySetInnerHTML={createMarkup(elemento.texto_post)}>

            </div>)}

            <FormsComment func={props.func} funcRegister={props.funcRegister} />
          </ContainerRecetteSingle>


        </ColumnRecettes>
      </ContainerRecette>

      <Footer />
    </>
  )
}
