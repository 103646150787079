import { useEffect } from 'react';
import { useState } from 'react';
import {Table,Titulo,ButtonPrimary,AlertSucess,AlertDanger} from './styles'
import './../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Link, useParams } from 'react-router-dom';
import NavMenuAdmin from '../../composant/navMenu/NavMenuAdmin';
import Sidebar from '../../composant/Sidebar/Sidebar';

import{useSelector, useDispatch} from "react-redux"
import { getSlidersLists } from '../../redux/sliderReducer';
//import './App.css';

//import Forms from '../Forms/Forms';
//import Sidebar from '../Sidebar/Sidebar';

export default function  Home () {

  const dispatch = useDispatch()
    const slider = useSelector(state => state.sliderReducer)

  const sliderUnicos = [];
  const url = "https://congolinaria.com.br/"
    let content
      if(!slider.items && !slider.loading && !slider.error){
          dispatch(getSlidersLists())
      }
      else if(slider.loading){
          //content = <img src={spinner} alt='spinning loader'/>
      }
      else if(slider.error){
          content = <p> An error has occured</p>
      }
      else if(slider.items){
        content =
        Object.values(slider.items).map((slider, index) => {
          return(
              <tr key={index}>
                <td>
                  {console.log(url+slider.imagem_slider)}
              <img  id="logo" src={url+slider.imagem_slider} alt="Congolinaria" />
                <p>{slider.titre_slider}</p>
              </td>
              <td> 
                <Link to={"/visualizar/"+ slider.id_slider}>
                  <ButtonPrimary><i class="fa-solid fa-eye"></i>Visualizar</ButtonPrimary></Link>
                <Link to={"/editarSlider/"+ slider.id_slider}
                state={{
                  titre: slider.titre_slider,
                  texto: slider.description_slider,
                  imagem: slider.imagem_slider,
                  id_slider: slider.id_slider,
                  date_created:slider.dateCreated_slider

              }}
                >
                  <ButtonPrimary><i class="fa-solid fa-pen"></i> Editar</ButtonPrimary></Link> 
                <ButtonPrimary onClick={()=>deletarSlider(slider.id_slider)}><i class="fa-solid fa-trash"></i>Apagar</ButtonPrimary>
                </td>
              </tr>
          );
          })

          
      }

  const [dataCardapio, setDataCardapio] = useState([]);
  const [statusEnvio, setEnvioStatus]= useState({
    type: '',
    mensagem:''
  })
  
  
  const getMenu = async () => {
    fetch(url + 'blog.php')     
    .then(response => response.json())
    .then(data => {
      console.log(data);
      setDataCardapio(data);
    })

  }
  /*
  const getRecette = async () => {
    fetch('/pilafs_et_risottos.json',{ 
    headers: {
      Accept: "application/json"}
    })     '
    .then(response => response.json())
    .then(data => {
      console.log(data);
      setDataCardapio(data);
      
    })
  }*/
  const deletarSlider = async (id_slider) =>{

    await fetch(url+"slider/deletar_slider.php?id="+ id_slider)
            .then((response)=> response.json())
            .then((responseJson)=>{
              if(responseJson.erro) {
                setEnvioStatus({
                  type: 'erro',
                  mensagem:responseJson.mensagem
                });
                
              }else{
                setEnvioStatus({
                  type: 'sucess',
                  mensagem:responseJson.mensagem
                });
                getMenu(); 
              }
                console.log(responseJson);                
            }).catch(()=>{
              setEnvioStatus({
                type: 'erro',
                mensagem:"Erro: Produto não apagado com sucesso tente mais tarde "

              });
              
            });
  };

  useEffect(() => {
    //getRecette();
    getMenu(); 
     }, [])

  return (
<>
<Sidebar/>
<NavMenuAdmin/>
      <section id='content'>
<main>
<div class="head-title">
				<div class="left">
					<h1>Dashboard</h1>
					<ul class="breadcrumb">
						<li>
							<a href="/admin/">Dashboard</a>
						</li>
						<li><i class='bx bx-chevron-right' ></i></li>
						<li>
							<a class="active" href="/admin/">Home</a>
						</li>
					</ul>
				</div>
				<a href="btn-download" class="btn-download">
					<i class='bx bxs-cloud-download' ></i>
					<span class="text">Download PDF</span>
				</a>
			</div>

			<ul class="box-info">
				<li>
					<i class='bx bxs-calendar-check' ></i>
					<span class="text">
						<h3>50</h3>
						<p>Recettes</p>
					</span>
				</li>
				<li>
					<i class='bx bxs-group' ></i>
					<span class="text">
						<h3>2834</h3>
						<p>Posts</p>
					</span>
				</li>
				<li>
					<i class='bx bxs-dollar-circle' ></i>
					<span class="text">
						<h3>$2543</h3>
						<p>Total Sales</p>
					</span>
				</li>
			</ul>


			<div class="table-data">
				<div class="order">
					<div class="head">
						<h3>Posts</h3>
						<i class='bx bx-search' ></i>
						<i class='bx bx-filter' ></i>
      {statusEnvio.type==='erro' ?<AlertDanger>{statusEnvio.mensagem}</AlertDanger> :""}
      {statusEnvio.type=== 'sucess' ? <AlertSucess>{statusEnvio.mensagem}</AlertSucess> :""}
					</div>
					<table>
					<thead>
          <tr>
            <th>Titúlo</th>
            
            <th>Ação</th>
            
          </tr>
        </thead>
		<tbody>
      {content}
        </tbody>
					</table>
				</div>
			</div>
</main>
    
    </section>
</>

  );
}


